<template>
  <b10-base>
    <v-alert
      v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      color="warning"
      dark
      small
    >
      Se creará un nuevo subsistema
    </v-alert>
    <b10-autocomplete
      v-model="form.idsubsis_idtsubsis"
      :items="subsisTsubsisFaltan"
      :rules="formRules.idsubsis_idtsubsis"
      item-value="value"
      item-text="descripcion"
      label="Subsistema"
      clearable
      @change="subsisTsubsisSelected"
    >
      <template
        #item="{ item, attrs, on }"
      >
        <v-list-item
          v-bind="attrs"
          :disabled="item.value.tipo === 'divider'"
          v-on="on"
        >
          <v-list-item-content
            v-if="item.value.tipo === 'divider'"
            :class="item.value.color"
            class="white--text pa-1 rounded"
          >
            {{ item.descripcion }}
          </v-list-item-content>
          <v-list-item-content
            v-else
          >
            {{ item.descripcion }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </b10-autocomplete>
    <v-text-field
      v-show="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
      v-model="form.subsis_descripcion"
      clearable
      flat
      label="Descripción del subsistema"
      :rules="formRules.subsis_descripcion"
    />
    <v-checkbox
      v-if="idparteTrabajo && !(form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis')"
      v-model="form.agregarMaterialAfectado"
      label="Agregar material afectado del subsistema seleccionado"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  props: {
    idordenTrabajo: {
      type: [Number, String],
      default: '',
    },
    idparteTrabajo: {
      type: [Number, String],
      default: '',
    },
    agregarMaterialAfectado: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      form: {
        idsubsis_idtsubsis: null,
        subsis_descripcion: null,
      },
      formRules: {
        idsubsis_idtsubsis: [
          v => !!v || 'Campo requerido'
        ],
        subsis_descripcion: [
          v => !!v || 'Campo requerido'
        ],
      },
      subsisTsubsisFaltan: [],
    }
  },
  async created () {
    this.subsisTsubsisFaltan = await this.$offline.ordenTrabajoSubsis.selectSubsisTsubsisFaltan(
      this.idordenTrabajo
    )
    this.$set(this.form, 'agregarMaterialAfectado', this.agregarMaterialAfectado)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    subsisTsubsisSelected () {
      if (this.form.idsubsis_idtsubsis?.descripcionPorDefecto) {
        this.$set(this.form, 'subsis_descripcion', this.form.idsubsis_idtsubsis.descripcionPorDefecto)
      } else {
        this.$set(this.form, 'subsis_descripcion', null)
      }
    },
  },
}
</script>
